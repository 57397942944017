.loginflex {
  display: grid;
  width: 69%;
  height: 600px;
  grid-template: "cr lf";
  margin: auto;
  margin-top: 20px;
  place-items: center;
}
.car {
  grid-area: cr;
  width: 70%;
  height: 500px;
  margin-top: 100px;
}
.logFm {
  grid-area: lf;
  max-height: 600px;
  width: 100%;
  box-shadow: 2px 2px 10px 1px #000000;
  text-align: left;
  padding: 20px;
}
.gridIm {
  width: 250px;
  height: 250px;
}
.head2 {
  margin: 10px;
  font-size: 16px;
  font-weight: 500px;
  color: #6c54da;
}
.logFm {
  grid-area: lf;
  max-height: 600px;
  width: 100%;
  box-shadow: 2px 2px 10px rgb(230, 225, 225);
  text-align: left;
  padding: 20px;
}
.logfm > span {
  font-size: 14px;
}
.logfm>h2{
  margin: 0;
}
.mainHead{
  font-weight: 500;
  margin: 0;
}
.inp {
  width: 90%;
  height: 30px;
  color: black;
  background-color: #f8f7f7;
  margin: 15px 0px;
  border: 1px solid rgba(0, 128, 128, 0.281);
  padding:5px 10px;
}
.btn {
  width: 98%;
  color: whitesmoke;
  height: 40px;
  background-color:#6c54da;
  border-radius: 2px;
  font-size: 14px;
  margin: auto;
  margin-top: 2%;
  cursor: pointer;
  border: none;
  transition: all 300ms;
}
.btn:hover {
  background-color: #4a2189;
}
.extra {
  text-align: center;
}
.extra > span {
  font-size: 14px;
}
.imIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
}
.btn2{
    width: 100%;
    color:#6c54da;
    height: 40px;
    background-color: #fbf9fc;
    border-radius: 2px; 
    font-size: 14px;
    margin: auto;
    font-weight: 600;
    margin: 20px 0px;
    border: 2px solid #6c54da;
    cursor: pointer;
    transition: all 300ms;
}
.btn2:hover{
    background-color: #4a2189;
    color: white;
}
.spaan{
  color: teal;
  font-size: 14px;
}
.socials{
  margin-top:0px;;
}
.subHead{
  color: teal;
  font-size: 14px;
  margin: 10px;
}
.forget{
  text-align: right;
  padding:0 20px;

}
.social{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.social img{
  width: 30px;
}
.social button{
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 992px) {
  .car{
    display: none;
  }
} 