.Reportbody{
    width: 790px;
height:fit-content;
background: white;
margin-left: 8%;
font-family: unset;
margin-bottom: 10px;
padding: 5px;
  
}
.ReportHead{
    text-align: left;
margin-left: 25px;
font-size: 23px;
font-weight: 600;

}
.ReportData{
width: 90%;
display: flex;
flex-direction: row;

}
.Report1Data{
min-width: 300px;
margin-right: 30px;
text-align: left;
margin-left: 25px;
background: rgb(244,244,245);
height:fit-content;
margin-bottom: 20px;
padding: 20px;
}

.Report1DataHead>img{
    width: 30px;
    height:30px;
    
    }
    .Report1DataHead>p{
       margin-top: 5px;
       margin-left: 5px;
       font-size: 18px;
       font-weight: 500;   
        }
.Report1DataHead{
        width: 100%;
        height:20px;
        display: flex;
        flex-direction: row;
        justify-content: left;

        
    }
    @media only screen and (max-width: 992px) {
        .Reportbody{
            width: 78.5%;
          height: fit-content;
        }
        .ReportData{
            display: flex;
            flex-direction: column;
            

            }


    }