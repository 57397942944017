.carouselBoxContainer{
    max-width: 800px;
    width: 100%;
    
}

.carouselBox{
    display: flex;
    overflow: auto;
    padding: 0 40px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    scroll-behavior: smooth;
    transition: all 300ms ease-in-out;
}
.carouselBox::-webkit-scrollbar{
    visibility: hidden;
}
.jobCards{
    width: 150px;
    border: 1px solid rgba(110, 109, 109, 0.164);
    border-radius: 2px;
    margin: 0 10px;
    background-color: #f5f5f5;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}
.jobCards:hover{
    box-shadow: 0px 0px 10px rgba(0, 128, 128, 0.5);
}
.jobCards *{
    margin: 0;
    font-size: 12px;
}
.ellipsis{
    text-overflow: ellipsis; 
    white-space: nowrap;
    overflow: hidden; 
    color: teal;
    width: 150px;
}
.carouselBoxMain{
    text-align: left;
    margin:10px 0;
    border: 1px solid rgba(151, 151, 151, 0.322);
    padding: 0 15px 25px 15px;
    position: relative;
    background-color: white;
}
.carouselBoxMain>h2{
    font-weight: 500;;
}
.prevBtn{
    position: absolute;
    left: 20px;
    top: 115px;
    display: flex;
    align-items: center;

}
.nextBtn{
    position: absolute;
    right: 5px;
    top: 115px;
    padding:0 10px;

}
.nextBtn >button,.prevBtn>button{
    box-shadow: 0px 0px 2px rgb(7, 197, 197);
    padding: 5px 12px;
    border-radius: 50%;
    border: none;
    font-weight: 800;
    font-size: 18px;
    background-color: white;
    transition: all 300ms ease-in-out;
}
.nextBtn >button:hover,.prevBtn>button:hover{
    box-shadow: -1px -1px 5px rgb(8, 196, 196);
}
.addBox{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.addBox img{
    margin: 10px;
}

@media only screen and (max-width: 992px) {
    .addBox{
      display: none;
    }
  } 