body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

button,input:focus{
outline: none;
}
button{
  cursor: pointer;
}
.flexBox{
  display: flex;
  justify-content: center;
}
.HomePageBody{
background-color: #f5f5f5;
}
.maxWidth{
  max-width: 1150px;
  margin: auto;
}