.Expertbody{
    width: 109%;
    max-width: 780px;
    height:320px;
    background-color: white;
    margin-left: 8%;
    font-family: unset;
    margin-bottom: 20px;
    padding: 10px;
    
    
}
.ExpertHead{
    text-align: left;
    width: 100%;
    margin-left: 25px;
    font-size: 23px;
    font-weight: 600;
    
}
.ExpertData{
    width: 80%;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    
   
    
}
#Expertimage>img:hover{
    width: 220px;
    cursor: pointer;   
}
@media only screen and (max-width: 992px) {
    .ExpertData{
        width: 95%;
        min-width: none;
        height: fit-content;
       overflow-x: scroll;
       overflow-y: hidden;
       height: 200px;
    }
    .Expertbody{
     width: 77%;
   

    }

 

}

   

    
