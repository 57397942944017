.filterCard{
background-color: white;
padding:0 10px;
margin: 2px;
text-align: left;
max-width: 200px;
}

.displayNone{
display: none;
}
.filterList{
    padding: 5px;
}
.filterCardHead{
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
}
.filterList label{
    color: teal;
    font-size: 12px;

}
.filterCardHead button{
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: 500;
}
.filterHead{
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 10px;
    text-align: left;
    color: teal;
}
.filter{
    padding: 10px;
}