
.monster{
   width:90%;
   max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
   justify-content: center;
   margin-left: 8%;
  
  

   
}

.header{
    
    padding-left: 33px;
}
.card_body{
    overflow-X: scroll;
    display: flex;
}
.resume__card{
    margin: 10px;
    display: flex;
    background-color: rgb(240, 239, 239);
    width: 500px;
    flex-direction: row;
    
    
}
.resume__description{
    display: flex;
    flex-direction: column;
    width: 140px;
    margin-top: -17px;
    padding-left: 15px;
    text-align: left;
}

.resume__description>p{
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

}

.resume__description>h3{
    margin-top: -4px;
    color: rgb(23, 74, 184);
    font-size: 14px;
    font-weight: 390;
    align-self: flex-end;
}

.mobile__number{
    display: flex;
    margin-top: 28px;
    width: 100%;
   background-color: rgb(100, 91, 187);
   color: white;
   padding-left: 20px;
   font-size: 14px;
   flex-direction: row;
   line-height: 15px;
}


.mobile__number>input{
    width: 15rem;
    height: 30px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    margin-left: 20px;
    border-radius: 4px 0px 0px 4px;
    border: none;
}
.mobile__number>button{
    width: 13rem;
    height: 50px; 
    margin-top: 15px ;
    background-color:rgb(244,166,34);
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border-radius: 0px 4px 4px 0px;
    margin-right: 20px;
}

.mobile__number>button:hover{

    background-color:rgb(219, 106, 41);
}

.mobile__number>input:focus{
    outline: none;
    
}
@media only screen and (max-width: 992px) {

    .monster{
        width:80%;
    }


    .mobile__number{
        display: flex;
       flex-direction: column;
       margin: auto;
    }
    .mobile__number>input{
        width: 60%;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 4px 4px 4px 4px;
       
    }
        .mobile__number>button{
            width: 64%;
            margin: auto;
            margin-bottom: 10px;
            border-radius: 4px 4px 4px 4px;
            
        }
  } 