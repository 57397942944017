.mainDiv {
  box-sizing: border-box;
  width: 50%;
  margin: auto;
 background-color: white;
  text-align: left;
  padding:10px;
}

.socialLinks {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.socialLinks > button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  border-radius: 100px;
  height: 35px;
  border: 1px solid rgba(0, 128, 128, 0.356);
  color: teal;
  transition: all 300ms ease-in-out;
}
.socialLinks > button:first-child:hover {
  background-color: rgb(177, 55, 55);
  color: white;
}
.socialLinks > button:last-child:hover {
  background-color: rgb(112, 112, 219);
  color: white;
}
.socIcons {
  width: 25px;
  margin: 0 5px;
}
.inp{
    width:92%;
    margin:5px;
    height: 27px;
    margin: 20px;
    margin-top: 0;
    padding:5px;
    border: 1px solid rgba(0, 128, 128, 0.39);

}
.selec{
    width:83%;
    margin:5px;
    margin-left: 3px;
    margin-top: 0;
    height: 29px;
    border: 1px solid rgba(0, 128, 128, 0.39);
}
.sel{
    width: 94%;
    height:35px;
    margin: 20px;
    border: 1px solid rgba(0, 128, 128, 0.39);
    margin-top: 0;
}
.pincode{
    width: 7%;
    height:35px;
    margin: 20px;
    border: 1px solid rgba(0, 128, 128, 0.39);
}
.btn{
    margin-left:60%;
    background-color: rgb(119, 190, 12);
    width: 200px;
    height:40px;
    border:none;
    font-size:20px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    text-align: center;
}
.btn:hover{
  background-color: rgb(99, 156, 12);
  box-shadow: 0px 0px 2px teal;
}
.resume{
    width:80%;
    margin:20px;
    height: 30px;
    font-size: 20px;
    padding: 3px;
    border: 1px solid rgba(0, 128, 128, 0.39);
    margin-top: 5px;
    color: teal;
    font-size: 14px;
}
.resume::-webkit-file-upload-button{
  background-color: rgba(216, 212, 212, 0.39);
  border: 1px solid rgba(0, 128, 128, 0.39);
  font-size: 14px;
  padding: 7px;
  color: teal;
}
.label{
    width:90%;
    margin-top: 15px;
    height: 30px;
    margin: 20px;
    font-size: 14px;
    color: teal;
}
.goo{
  font-size: 14px;
}
.res{
  height: 50px;
  width :450px;
  background-color: white;
}
.para{
  color: rgb(119, 118, 118);
  font-size: 12px;
  width: 95%;
  margin: auto;
  
}
.mainHead{
  font-weight: 500;
  font-size: 20px;
  color: rgb(1, 70, 70);
margin:10px auto 0 auto;
}
.subHead{
  font-weight: 300;
  font-size: 14px;
  color: teal;
  margin: 0;
}
.center{
  text-align: center;
}