.resultCard{
    max-width: 750px;
width: 100%;
    text-align: left;
    margin:10px auto;
    background-color: white;
}
.resultCard>a{
    text-align: left;
    cursor: pointer;
    text-decoration: none!important;
    color: black;

}
.resultCard>a>div{
    padding: 10px!important;
    text-align: left;
    cursor: pointer;
    text-decoration: none!important;

}
.resultCard:hover{
box-shadow: 0px 0px 10px teal;
}
.resultCard img{
    width: 15px;
}
.space{
 justify-content: space-between;
}
.resultCard h4{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.resultCard a{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.resultCard p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.applyNow{
justify-content: flex-end;
background-color: rgb(226, 226, 226);
}
.applyNow a{
border: 2px solid blue;
color: blue;
text-decoration: none;
font-weight: 500;
padding:5px 10px;
transition: all 300ms ease-in-out;
}
.applyNow a:hover{
    color: white;
    background-color: blue;
    box-shadow: 0px 0px 10px teal;
}
.ResultBody{
    display: flex;
    justify-content: center;
}
.resultFilterBody{
    max-width: 240px;
    width: 20%;
}
.resultCardContainer{
    max-width: 750px;
    width: 70%;
}
.appliedFiltersList{
 text-align: left;
 font-size: 12px;
 width: 750px;
 word-wrap: none;
}
.appliedFiltersList span{
    border: 1px solid rgb(1, 196, 196);
    background-color: white;
    padding:0 5px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}
.appliedFiltersList span:hover{
    box-shadow: 0px 0px 2px teal;
}
.searchResultCount{
    font-size: 14px;
}
.noResult{
    padding: 20px;
    background: white;
    width: 850px;
    margin: 30px auto;
}
.noResult > *{
    margin: 10px;
}
.registerAddBox{
    width: 150px;
    margin: 10px;
    padding: 10px;
    background-color: white;
    height: fit-content;
    margin-top: 40px;
}
.registerAddBox *,.careerAddBox *{
    margin: 0;
    font-size: 14px;
    
}
.careerAddBox{
    width: 150px;
    margin: 10px;
    padding: 10px;
    background-color: rgb(214, 211, 211);
    height: fit-content;
    margin-top: 40px;
    text-align: left;
}
.careerAddBox a{
    background-color: rgb(68, 68, 248);
    color: white;
    padding:5px 10px;
    margin: 20px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 5px;
}
.AddBox{
    background-color: white;
}



@media only screen and (max-width: 992px) {
    .resultAddBox{
      display: none;
    }
  } 