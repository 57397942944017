
.navBar{
    display: flex;
    height: 70px;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px -2px 10px rgb(116, 113, 113);
    position: sticky;
    top :0;
    background-color: white;
    z-index: 11;
  }
  .navBar .logo img{
    width: 130px;
    height: 36px;
  }
  .navBarCards{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navBar a{
    text-decoration: none;
    color: black;
    font-size: 14px;
    margin:0 10px;
    font-weight: 400;
    transition: all 300ms;
  }
  .navBar a:hover{
    color: blue;
  }
  .covidCard{
    background-color: #e16f62;
    color: white;
    padding:15px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 200ms;
  }
  .covidCard strong{
    font-size: 12px;
    font-weight: 500;
  }
  .covidCard:hover{
    background-color: #d43826;
  }
  .navBar .loginBtn{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border:  1px solid black;
    border-radius: 5px;
    padding: 5px;
    height: 20px;
    font-weight: 500;
    width: 150px;
  }
  .navBar .loginBtnEmp{
    font-size: 12px;
    color: #5d4da8;
  }
  .navBar .loginBtnEmp:hover{
    color: orange;
  }
  .userProfile img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid white;
    box-shadow: 0px 0px 0px 2px teal;
    margin: 10px;
  
  }
  .userProfile{
    margin: 10px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    text-align: left;
  }
  .userProfile p{
    font-size: 12px;
    margin: 0;
  }
  .userProfile button{
    padding:10px 20px;
    background-color: transparent;
    border: none;
    color: white;
    box-shadow: 0px 0px 2px teal;
    transition: all 300ms;
    font-weight: 500;
    background-color: rgb(116, 116, 252);
  }
  .userProfile button:hover {

  box-shadow: 0px 0px 5px teal;
  }

  @media only screen and (max-width: 992px) {
    .covidCard{
      display: none;
    }
  } 