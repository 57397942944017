.MainTip_1{
    width: 100%;
   height: fit-content;
   margin-top: -38px;
   margin-bottom: 20px;
}
.MainTip_1>p{
    width: 100%;
    background-color:rgb(109,84,218) ;
    min-height: 80px;
    color: white;
    font-size: 40px;
    padding-top: 20px;
    height: fit-content;
}
.mainTip_1_1{
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;

}
.mainTip_1_1_1{
  width: 75%;
   display: flex;
   flex-wrap: wrap;

  


}
.mainTip_1_1_2{
     width: 34%;
     font-size: 12px;
     text-align: left;
     background-color: white;
     height: 323px;
     margin-left: 25px;
}
.mainTip_1_1_2>ul{
    width: 87%;

}
.mainTip_1_1_2_1>p{
    width: 87%;
    font-size: 16px;
    color:rgb(109,84,218) ;
    

    
}
.mainTip_1_1_2_1{
   display: flex; 
   margin-left: 20px;
   height: 30px;
  
}
.mainTip_1_1_2_1>img{
   height: 30px;
   width: 30px;
   margin-top: 10px;
   margin-right: 20px;
 }
.mainTip_1_1_1_1{
    width: 18%;
    min-width: 100px;
    height: 130px;
    border: 1px solid rgb(218, 214, 214);
    font-size: 12px;
    padding: 10px 20px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    justify-content: center;
   
}
.mainTip_1_1_1_1>div{
    width: fit-content;
    height: fit-content;
    object-fit: contain;
    margin: auto;
}
.mainTip_1_1_1_1>div>img{
    width: 80px;
    height: 80px;
   
}
.mainTip_1_1_1_1:hover{
    background-color: white;
    cursor: pointer;

}
.carrer_article>p{
    margin: auto;
    width: 80%;
    height: 50px;
    display: grid;
    place-items: center;
    background-color:rgb(109,84,218) ;
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
}
.carrer_article_data_div{
    width: 81.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    
}
.carrer_article_data{
    width: 250px;
    height: 260px;
    justify-content:space-around ;
    background-color: white;
    margin: 10px;
   
}
.carrer_article_data>P{
    font-size: 16px;
    color: rgb(109,84,218);
    text-align: left;
    margin-left: 10px
    
    
}
.carrer_article_details{
   
    margin: auto;
    font-size: 14px;
    color: rgb(141, 139, 139);
    height: 125px;
    width: 225px;
    text-align: left;
}
#btn{
  font-size: medium;
    padding: 0.5rem;
    width:100px;
    outline: none;
    border: none;
    background-color: rgb(109,84,218);
    color: white;
  

  
}
.carrer_article_data:hover{
   
    cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .mainTip_1_1{
      flex-direction: column;
  }
  .mainTip_1_1_2{
      width: 100%;
      height: fit-content;
      margin-left: 0px;
  }
  .mainTip_1_1_1{
      width: 100%;
     
     
  }
  .mainTip_1_1_1_1{
      width: 37%;

      }
      .carrer_article_data{
          width: 100%;
          height: fit-content;
          text-align: left;
          align-items: flex-end;
      }
      .carrer_article_details{
       padding-left: 10px;
        width: 100%;
      }
    



 

}