.searchJob_fullBody{
    background-color: rgb(244,244,245);
    margin: 20px auto;
    margin-top: 0;
}
.searchJob_fullBody form{
    max-width:700px;
    width: 80%;
    padding:0 20px;
}

.multipleInputs{
display: flex;
justify-content: flex-start;
padding: 0 28px 0 32px;

}
.searchBoxAdvance{
    justify-content: center;
}
.searchJob_adv{
    max-width:1000px;
    padding-top: 50px;
    text-align: left;
    margin: auto;
    width: 90%;
}
.searchJob_adv>h2{
  margin-top: 20px;
}

.searchJob_inputBody{
    width: 95%;
    background-color: white;
    float: left;
    height: fit-content;
    padding-top: 30px;
}
.searchjob_searchTipBody{
    width:20%;
    background-color: white;
    height: fit-content;
    color: blue;
    font-family: unset;
    font-size: 15px;
    
     
}
.tipImage_body{
    float: left;
    width: 20%;

}
.tipData{
    color: black;
}
.tipbox{
    text-align: left;
    width: 95%;
    font-size: 14px;
    padding:0 10px;
}
.tipData{
    color: teal;
    font-size: 12px;
}
.input>input{
    background: none;
    border: none;
    width: 98%;
    margin-top: 8px;
    margin-left: 3px;
    font: 15px;
    font-family: unset;

}
.inputi>input{
    background: none;
    border: none;
    width: 98%;
    margin-top: 8px;
    margin-left: 3px;
    font: 15px;
    font-family: unset;

}
.input{
    height: 40px;
    border: 1px solid rgb(205,205,204);
    margin-top: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   
    
    
}
.inputi{
    height: 40px;
    border: 1px solid rgb(205,205,204);
    margin-top: 20px;
    width: 43%;
    flex-grow: 1;
    margin-right: 5px;
   padding-left: 10px;
    

}


.search_button{
    width: 90%;
    height: 40px;
    margin-top: 12px;
    background: rgb(108,85,219);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    color: white;
    font-weight: 500;
    outline: none;
    border: none;
}
.search_button:hover{
    background: rgb(44, 19, 165);
    cursor: pointer;

}