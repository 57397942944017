.serviceCard{
    width: 350px;
    text-align: left;
    margin: 10px;
}
.serviceCard img{
    width: 100%;
}
.serviceCard button{
    background-color: transparent;
    border: 1px solid blue;
    padding: 10px 20px;

}
.serviceCard>div{
    padding: 10px;
    height: 250px;

}
.serviceCard>div p{
  min-height: 120px;
}
.OurServices{
    display: flex;
    justify-content: center;
}
.OurServicesMain>p{
    font-size: 26px;
}