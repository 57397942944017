.employerNavBar{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    align-items: center;
    background-color: #639;
    margin-bottom: 0;
}
.employerNavBar *{
    margin:0 10px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-decoration: none;
}
.employerNavBar img{
    width: 140px;
}
.employerNavBar a:hover{
    text-decoration: underline;
}
.employerNavBar button{
    padding:5px 10px;
    border: 2px solid white;
    color: white;
    background-color: transparent;
    transition: all 300ms ease-in-out;
}
.employerNavBar button:hover{
    border: 2px solid blue;
    color: blue;
    background-color: white;
}