
 .registerAdd{
    background-color: blue;
    color: white;
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
 
  }
  .registerAdd img{
    width: 50px;
  }
  .registerAddImg{
    align-items: center;
  }
  .registerAdd a{
   color: blue;
   background: white;
   padding: 5px 10px;
   text-decoration: none;
   font-weight: 500;
 }
 .registerAdd a:hover{
 box-shadow: 0px 0px 5px white;
 }