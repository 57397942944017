#jobCardDetailsBody{
   max-width: 1000px;
   margin: auto;
   padding: 20px;  
}
#JobCard_1_1{
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    padding:20px;
}
#JobCard_1_1>h4{
   margin:0;

}
#JobCard_1_1>button{

    border: 2px solid blue;
    color: blue;
    text-decoration: none;
    font-weight: 500;
    padding:5px 10px;
    transition: all 300ms ease-in-out;
    background-color: transparent;

}
#JobCard_1_1>button:hover{
  color: white;
  background-color: blue;
  box-shadow: 0px 0px 2px teal;
}

#JobCard_1_2{
  text-align: left;
  color:rgb(108,84,218);
  padding:0 20px;
  background-color: white;
}
#JobCard_1_2_1{
  display: flex;
  justify-content: flex-start;
  color: gray;
} 
#JobCard_1_2>p{
  margin: 0;
  padding: 0;
}
#JobCard_1_2_1>div>img{
  height: 15px;
  width:15px;
  margin-right: 10px;
}

#JobCard_1_2_1>div{
   height: 25px;
   width: fit-content;
   margin-right: 20px;
  }
  #JobCard_2{
    background-color: white;
    padding: 10px;
    margin: 10px auto;
  }

  #JobCard_2_1{
 text-align: left;
 font-size: 14px;
 width: 90%;
  }

  #JobCard_2>div>p{
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;

}
#JobCard_3{
  background-color: white;
  padding: 10px;
  margin: 10px auto;
}
#JobCard_3_1{
  text-align: left;
  border: 1px solid rgba(0, 128, 128, 0.288);
  padding: 10px;
  background-color: white;
}
#JobCard_3>div{
  text-align: left;
  font-size: 14px;
  margin-left: 20px;
  width: 90%;
  
  
}
#JobCard_3>p{
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;

  
}
.JobCard_3_1_skill{
  background-color:  rgb(234,235,235);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  width: fit-content;
  color:rgb(120,121,121);
  padding: 0px 10px;
  
}
.skill{
  display: flex;

}
#JobCard_3_1>div{
 margin-top: 20px;
  
}
#JobCard_3_1>p{
  text-align: left;
  font-size: 20px;
  font-weight: 500;
   
 }
 .similarJobs>h2{
   font-weight: 500;
   border-bottom:1px solid rgba(0, 128, 128, 0.322) ;

 }
 .similarJobs{
   max-width: 1000px;
   margin: auto;
   text-align: left;
 }
 .loadingBox{
   display: grid;
   place-items: center;
   height: 90vh;
 }