.courses{
    width: 800px;
    min-height:800px ;
    
    height: fit-content;
    min-height: 500px;
    margin-left: 8%;
    background-color: white;
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 16px;
}
.courses__header{
    margin-left: 20px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
   
}
.courses__description{
    margin: auto;
    width: 90%;
    font-size: 14px;
    text-align: left;
}


.courses__cards{
    display: flex;
    
}

.courses__card{
    width: 250px;
    height: 300px;
    margin-left: 50px;
    margin-bottom: 10px;
}
.coursescard__header{
    background-color: rgb(73,145,227);
    color: white;
    height: 100px;
    padding:"50px 10px 20px 30px"
}
.coursescard__header>h5>{
    
    font-size: 13px;
}

.courses__footer{
    height: 200px;
    border: 1px solid rgb(240, 232, 232);
    text-align: left;
    padding-inline-start: 10px;
    
}

.courses__footer>p{
       margin-left: 13px;
}

.courses__footer>li{
    font-size: 12px;
    list-style-type: symbols(">");
    margin: 8px 0;
}
@media only screen and (max-width: 992px) {
  .courses__cards{
      display: flex;
      flex-direction: column;
      min-height: 680px;
      justify-content: space-around;
      
      
  }  
  .courses{
   width: 80%;
  }
}