.profile_head {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile_body {
    margin: auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    
}

.profile_details {
    width: 25%;

    display: flex;
    flex-direction: column;
 
}

.profile_score {
    text-align: left;
    width: 100%;
    height: 83px;
    background-color: white;
    margin-bottom: 15px;

}

.profile_score>p {
    margin-left: 10px;
    margin-top: 1;


}

#percentage {
    font-size: 12px;
    margin-top: -1px;
    margin-left: 103px;
}

.score_bar {
    width: 90%;
    height: 15px;
    display: flex;
    margin-top: -10px;
    margin-left: 10px;

}

.completed {
    width: 40%;
    height: 15px;
    background-color: rgb(109, 85, 218);
}

.notcompleted {
    width: 68%;
    height: 15px;
    background-color: whitesmoke;
}

.Edit_details_part {
    width: 74%;
    padding: 10px;

    margin-bottom: 20px;
    margin-top: -10px;

}

.Add_information {
    width: 97%;
    height: 84px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;

}

.Add_information>p:nth-child(2) {
   text-align: left;
   margin-top: -1px;
   color: grey;
}

.Add_information>p:nth-child(1) {
    display: flex;
    color:rgb(109, 85, 218) ;
    font-size: 14px;
    justify-content: space-between;
}

#pendding_action {

    width: 100%;
    height: fit-content;
    background-color: white;


}

#pendding_action1 {
    width: 65%;
    margin-top: 10px;
    height: 25px;
    font-size: 20px;

    flex-wrap: wrap;
    display: flex;
    margin-left: 20px;
    color: gray;
    justify-content: space-between;
    background-color: white;


}

#add_data {

    width: 90%;
    height: 35px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    font-size: 14px;

}

.profile_data {
    margin-top: 20px;
    background-color: white;

}